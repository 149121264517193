export enum ProjectStatus {
    ACTIVE = "ACTIVE",
    ARCHIVED = "ARCHIVED",
}
export enum StatusFilter {
    NONE = "NONE",
    NOT_STARTED = "NOT_STARTED",
    RUNNING = "RUNNING",
    FAILED = "FAILED",
    SUCCESS = "SUCCESS",
}
export enum TestFailureReason {
    EXIT_CODE_NON_ZERO = "EXIT_CODE_NON_ZERO",
    REFERENCE_IMAGE_MISSING = "REFERENCE_IMAGE_MISSING",
    OUTPUT_IMAGE_MISSING = "OUTPUT_IMAGE_MISSING",
    REFERENCE_AND_OUTPUT_IMAGE_MISSING = "REFERENCE_AND_OUTPUT_IMAGE_MISSING",
    IMAGES_ARE_NOT_EQUAL = "IMAGES_ARE_NOT_EQUAL",
    TIMED_OUT = "TIMED_OUT",
}
export enum UnifiedTestStatus {
    NOT_STARTED = "NOT_STARTED",
    RUNNING = "RUNNING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}
export enum RunStatus {
    NOT_STARTED = "NOT_STARTED",
    RUNNING = "RUNNING",
    SUCCESS = "SUCCESS",
    FAILED = "FAILED",
}
export enum ImageTranscodingState {
    WAITING_FOR_TRANSCODING = "WAITING_FOR_TRANSCODING",
    TRANSCODED = "TRANSCODED",
    UNABLE_TO_TRANSCODE = "UNABLE_TO_TRANSCODE",
}
export enum ComparisonMethod {
    SSIM = "SSIM",
    FLIP = "FLIP",
}
export enum RunBatchProvider {
    LOCAL_COMPUTER = "LOCAL_COMPUTER",
    GITHUB_ACTIONS = "GITHUB_ACTIONS",
}
export enum OS {
    WINDOWS = "WINDOWS",
    LINUX = "LINUX",
    MAC_OS = "MAC_OS",
    UNKNOWN = "UNKNOWN",
}
export interface Project {
    id: number
    name: string
    status: ProjectStatus
    thumbnailFileId?: number
}
export interface CanBeEdited {
    canEdit: boolean
    message?: string
}
export interface TestEditCount {
    count: number
}
export interface TestResultShortSummaryDto {
    id: number
    name: string
    testIdentifier: string
    unifiedTestStatus: UnifiedTestStatus
    thumbnailFileId?: number
    seconds?: number
}
export interface SuiteResultDto {
    id: number
    runId: number
    suiteName: string
    suiteVariables: {[index: string]: string}
    status: RunStatus
}
export interface TestResultDto {
    id: number
    suiteResultId: number
    testName: string
    testIdentifier: string
    testCommand: string
    testVariables: {[index: string]: string}
    machineInfo: MachineInfo
    unifiedTestStatus: UnifiedTestStatus
    seconds: number
    message?: string
    imageOutput?: Image
    referenceImage?: Image
    diffImage?: Image
    startedAt?: string
    finishedAt?: string
    comparisonSettings?: ComparisonSettings
    errorValue?: number
    thumbnailFileId?: number
}
export interface MachineInfo {
    cpuName: string
    cores: number
    memory: number
}
export interface Image {
    id: number
    name: string
    originalFileId: number
    channels: ImageChannel[]
    width: number
    height: number
    transcodingState: ImageTranscodingState
}
export interface ImageChannel {
    id: number
    imageId: number
    name: string
    fileId: number
}
export interface ComparisonSettings {
    method: ComparisonMethod
    threshold: number
}
export interface CreateFullRunDto {
    projectId: number
    runBatchIdentifier: RunBatchIdentifier
    testSuites: TestSuiteForRunCreation[]
    runInformation: BasicRunInformationForRunCreation
    branchName?: string
}
export interface RunBatchIdentifier {
    provider: RunBatchProvider
    runName: string
    runIdentifier: string
}
export interface TestSuiteForRunCreation {
    suiteName: string
    suiteVariables: {[index: string]: string}
    tests: TestForRunCreation[]
}
export interface TestForRunCreation {
    testName: string
    testIdentifier: string
    testCommand: string
    testVariables: {[index: string]: string}
    comparisonSettings: ComparisonSettings
}
export interface LocalComputerRunInformationForRunCreation {
    os: OS
    computerName: string
    localUsername: string
    runInformationType: "LOCAL_COMPUTER"
}
export interface GithubActionsRunInformationForRunCreation {
    os: OS
    computerName: string
    githubRunId: number
    htmlUrl: string
    jobName: string
    actor: string
    attempt: number
    runNumber: number
    githubUrl: string
    githubApiUrl: string
    runInformationType: "GITHUB_ACTIONS"
}
export type BasicRunInformationForRunCreation = LocalComputerRunInformationForRunCreation | GithubActionsRunInformationForRunCreation;
export interface AuthUser {
    id: number
    username: string
    fullname: string
    email: string
}
export interface LocalComputerRunInformation {
    id: number
    runId: number
    os: OS
    computerName: string
    localUsername: string
    runInformationType: "LOCAL_COMPUTER"
}
export interface GithubActionsRunInformation {
    id: number
    runId: number
    os: OS
    computerName: string
    githubRunId: number
    htmlUrl: string
    jobName: string
    actor: string
    attempt: number
    runNumber: number
    githubUrl: string
    githubApiUrl: string
    runInformationType: "GITHUB_ACTIONS"
}
export type BasicRunInformation = LocalComputerRunInformation | GithubActionsRunInformation;
export interface RunAggregate {
    id: number
    projectId: number
    createdAt: string
    status: RunStatus
    duration: number
    branchName: string
    runInformation: BasicRunInformation
    suiteCount: number
    testCount: number
    progress: RunProgress
    performanceTraceId?: number
}
export interface RunProgress {
    waitingTestCount: number
    runningTestCount: number
    failedTestCount: number
    succeededTestCount: number
    progressPercentage: number
}
export interface RunBatchAggregate {
    id: number
    runBatchIdentifier: RunBatchIdentifier
    projectId: number
    createdAt: string
    runs: RunAggregate[]
    status: RunStatus
    duration: number
    branchName: string
    suiteCount: number
    testCount: number
    progress: RunProgress
}
export interface SuiteResultSummaryDto {
    id: number
    runId: number
    suiteName: string
    suiteVariables: {[index: string]: string}
    status: RunStatus
    tests: TestResultShortSummaryDto[]
}
